.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px; /* Adjust padding as needed */
  background-color: rgba(255, 255, 255, 0.2); /* Glass effect background */
}

.logo-container {
  flex: 0 0 auto; /* Prevent logo container from growing or shrinking */
}
.header-text a {
  margin: 0;
  font-family: 'Rubik', sans-serif; /* Choose a modern font family */
  font-size: 24px; /* Adjust font size as needed */
  font-weight: 600; /* Adjust font weight as needed */
  color: #333; /* Choose initial text color */
  text-decoration: none; /* Remove default underline for links */
  transition: color 0.3s ease; /* Add transition for color animation */
}
.header-text  {
  margin: 0;
}

.header-text a:hover {
  color: #b5b1b2; /* Change text color on hover */
}




.button-container {
  display: flex;
}

#save-button,
#export-button {
  font-family: 'Rubik', sans-serif; /* Use the Rubik font or any other desired font */
  font-size: 16px; /* Adjust font size as needed */
  font-weight: 500; /* Adjust font weight as needed */
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#save-button:hover,
#export-button:hover {
  background-color: #333;
}

.button-link {
  font-family: 'Rubik', sans-serif; /* Use the Rubik font or any other desired font */
  font-size: 16px; /* Adjust font size as needed */
  font-weight: 500; /* Adjust font weight as needed */
  color: #fff; /* Text color */
  text-decoration: none; /* Remove underline */
  border: none; /* Remove button border */
  border-radius: 5px; /* Apply border radius for rounded corners */
  cursor: pointer; /* Change cursor to pointer on hover */
}




.email-editor {
  width: 100%; /* Optionally, set width to 100% */
  height: 100vh;
}

.sc-eNSrOW.cpUIjR.blockbuilder-preview.design-web.design-email.editor-desktop.right {
  background-color: #f0f0f0; /* Replace with your desired background color */
}
